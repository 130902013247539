<template>
  <div class="flex">
    <div class="flex-1 min-w-[890px]" style="min-width: 890px">
      <div>
        <a-card title="待报价" class="mycard">
          <template #extra>
            <a-button type="link" @click="quoteQuotationaAll">全部</a-button>
          </template>
          <a-table
            :data-source="quoteData"
            :columns="quoteColumns"
            :loading="quoteLoading"
            rowKey="id"
            :pagination="false"
          >
            <template #commitBy="{ record, text }">
              <span>{{ text }}</span>

              <a-tooltip v-if="record.chatUser" :title="record.chatUser.cellphone">
                <PhoneFilled class="ml-8px cursor-pointer" style="color: #0d8d8d; transform: rotateY(180deg)" />
              </a-tooltip>
              <MessageFilled
                v-if="record.chatUser"
                @click="webim.open(record.chatUser)"
                class="ml-8px cursor-pointer operation-model"
                style="color: #ffd100"
              />
            </template>
            <template #branchName="{ text, record }">
              <a-tooltip :title="text">
                <span>{{ record.tenantShortName }}</span>
              </a-tooltip>
            </template>
            <template #operation="{ record }">
              <a class="text-primary operation-model" @click="quoteQuotationClick(record)">去报价</a>
            </template>
          </a-table>
        </a-card>
      </div>

      <div class="mt-20px">
        <a-card title="待接单" class="mycard">
          <template #extra>
            <a-button type="link" @click="orderAllClick">全部</a-button>
          </template>
          <a-table
            :data-source="orderData"
            :columns="orderColumns"
            :loading="orderLoading"
            rowKey="id"
            :pagination="false"
          >
            <template #branchName="{ text, record }">
              <a-tooltip :title="text">
                <span>{{ record.inquiry.tenantShortName }}</span>
              </a-tooltip>
            </template>
            <template #preparedBy="{ record, text }">
              <span>{{ text }}</span>
              <a-tooltip v-if="record.chatUser" :title="record.chatUser.cellphone">
                <PhoneFilled
                  class="cursor-pointer"
                  style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
                />
              </a-tooltip>
              <MessageFilled
                v-if="record.chatUser"
                @click="webim.open(record.chatUser)"
                class="ml-8px cursor-pointer operation-model"
                style="color: #ffd100"
              />
            </template>
            <template #operation="{ record }">
              <a class="text-primary operation-model" @click="orderDetailClick(record)">去接单</a>
            </template>
          </a-table>
        </a-card>
      </div>
    </div>

    <div class="ml-20px w-[380px]" style="width: 380px">
      <div>
        <a-card title="我的团队" class="mycard">
          <template #extra>
            <a-button type="link" @click="managerTeam">全部</a-button>
          </template>
          <a-table
            :data-source="teamData"
            :columns="teamColumns"
            :loading="teamLoading"
            rowKey="id"
            :pagination="false"
            :showHeader="false"
          ></a-table>
        </a-card>
      </div>

      <div class="mt-20px">
        <a-card :title="store.state.supplier.businessType.code === 'W' ? '经营品牌' : '经营易损件'" class="mycard">
          <!-- <template #extra>
            <a-button type="link">全部</a-button>
          </template> -->
          <div class="flex flex-wrap">
            <span class="px-8px py-5px mr-10px mb-12px" v-for="(brand, index) of supplier.brands" :key="index">{{
              brand
            }}</span>
            <span
              class="h-32px px-8px py-5px border border-primary cursor-pointer color-primary"
              style="color: #0d8d8d"
              @click="addBrands"
              >+ 添加</span
            >
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'

import { Card as ACard, message, Tooltip as ATooltip } from 'ant-design-vue'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'
import { PhoneFilled, MessageFilled } from '@ant-design/icons-vue'

import { useAjax, formatDate, formatMoney, useTable2, useStore, useWebim } from '@vue-mfe/utils'

interface QuoteState {
  id: number
  vin: string
  vehicleSpec: string
  branchName: string
  commitBy: string
  inqueryTime: string
}

interface OrderState {
  id: number
  vehicleSpec: string
  branchName: string
  preparedBy: string
  amount: number
  commitDatetime: string
}

interface TeamState {
  id: number
  name: string
  cellphone: string
  role: {
    type: string
  }
}

interface SupplierState {
  vehicleBrands: string[]
  parts: any[]
  customKeywords: any[]
  businessType: {
    code: string
  }
}

defineProps({})
const webim = useWebim()
const router = useRouter()
const store = useStore()
const {
  data: quoteData,
  loading: quoteLoading,
  columns: quoteColumns
} = useTable2<QuoteState>(
  {
    columns: [
      // { title: '序号', customRender: ({ index }) => index + 1 },
      { title: 'VIN码', dataIndex: 'vin' },
      {
        title: '车型',
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      { title: '采购商', dataIndex: 'branchName', slots: { customRender: 'branchName' } },
      { title: '询价人', dataIndex: 'commitBy', slots: { customRender: 'commitBy' } },
      { title: '询价时间', customRender: ({ record }) => formatDate(record.commitDatetime) },
      { title: '操作', slots: { customRender: 'operation' } }
    ],
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /supplier/quotation/inquiry',
        params: { quoteStatus: 'P' }
      }
    }
  }
)

const quoteQuotationClick = (record: QuoteState) => {
  router.push({ name: 'supplier-supply-quote-_id', params: { id: record.id } })
}
const quoteQuotationaAll = () => {
  router.push({ name: 'supplier-supply-quote' })
}

const orderColumns: ColumnProps[] = [
  // { title: '序号', customRender: ({ index }) => index + 1 },
  {
    title: '车型',
    customRender: ({ record }) =>
      record.inquiry.vin
        ? `${record.inquiry.vehicleBrand}${
            record.inquiry.vehicleSubBrand ? ' ' + record.inquiry.vehicleSubBrand : ''
          } ${record.inquiry.vehicleSystem} ${record.inquiry.vehicleYear} ${record.inquiry.vehicleDisplacement}`
        : record.inquiry.vehicleBrand
  },
  { title: '采购商', dataIndex: 'inquiry.branchName', slots: { customRender: 'branchName' } },
  { title: '下单人', dataIndex: 'preparedBy', slots: { customRender: 'preparedBy' } },
  { title: '总价', dataIndex: 'amount', customRender: ({ text }) => formatMoney(text, '￥') },
  { title: '下单时间', dataIndex: 'preparedDatetime', customRender: ({ text }) => formatDate(text) },
  { title: '操作', slots: { customRender: 'operation' } }
]

const { data: orderData, loading: orderLoading } = useAjax<OrderState>(
  {
    action: 'GET /supplier/purchase',
    params: () => ({ pageSize: 5, status: 'P' })
  }
)
const orderDetailClick = (record: QuoteState) => {
  router.push({ name: 'supplier-supply-order-_id', params: { id: record.id } })
}
const orderAllClick = () => router.push({ name: 'supplier-supply-order' })

const teamColumns: ColumnProps[] = [
  { width: 100, ellipsis: true, dataIndex: 'name' },
  { width: 130, dataIndex: 'cellphone' },
  { dataIndex: 'role.message' }
]

const { data: teamData, loading: teamLoading } = useAjax<TeamState>({ action: 'GET /user', params: { pageSize: 10 } })
const managerTeam = () => {
  if (store.state.user.role && store.state.user.role.code === 'M') {
    router.push({ path: '/supplier/setting', query: { tab: 'team' } })
  } else {
    message.warning(
      {
        title: '提示',
        content: '您无权设置此项!'
      }
    )
  }
}

let supplier = reactive(
  {
    brands: [] as string[]
  }
)
useAjax<SupplierState>(
  {
    action: 'GET /supplier/security/currentsupplier',
    success (data) {
      if (data[0].businessType.code === 'W') {
        supplier.brands = data[0].vehicleBrands
      } else {
        supplier.brands = data[0].parts
          .map((inner: any) => inner.message)
          .concat(data[0].customKeywords.map((inner: any) => inner.keyword))
      }
    }
  }
)
const addBrands = () => {
  if (store.state.user.role && store.state.user.role.code === 'M') {
    router.push({ path: '/supplier/setting', query: { tab: 'manage' } })
  } else {
    message.warning(
      {
        title: '提示',
        content: '您无权设置此项!'
      }
    )
  }
}
</script>
<style scoped>
.mycard :deep(.ant-card-head-title) {
  font-weight: bold;
}
</style>
